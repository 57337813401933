import { Link, useNavigate } from "react-router-dom";
import { Input, InputGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Modal, Nav,
    NavItem,
    NavLink } from "reactstrap";

import logo from '../../assets/images/osiz_lab-1.png';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CourseAction } from "../../Redux/actions/CourseAction";

const Header = (props) => {

   const [isOpen, setIsOpen] = useState(false);
   const toggleNav = () => setIsOpen(!isOpen);
   const navigate = useNavigate()
   let dispatch = useDispatch();
   const [courseName, setCourseName] = useState('')

   useEffect(()=>{
    dispatch(CourseAction.CourseList({},(resp)=>{
        try{
            if(resp.data.status === true){
                setCourseName(resp.data.resp)
            }
            else{
                // display a api reponse error
            }
        }
        catch(e){
            // display a catch error
        }
    }
    ))
   },[])


   return (
      <>
        <header className="fixed-top tophead" id="navbar">
            <div className="container">
                <div className="plnkhdr">
                    <nav className="navbar navbar-expand-xl">
                        <a onClick={()=>{navigate('/')}} className="navbar-brand">
                            <div className="log-img cursor-pointer">
                                <img src={logo} alt="" width="150px" className="img-fluid" />
                            </div>
                        </a>
                        <button className="navbar-toggler " type="button" onClick={toggleNav}>
                            <i className="fa fa-bars text-white"></i>
                        </button>
                        <Collapse isOpen={isOpen} navbar className="justify-content-end myNav">
                          <Nav className="navbar-nav justify-content-start justify-content-xl-center align-items-xl-center" navbar>
                            <NavItem className="pb-0">
                                <UncontrolledDropdown>
                                    <DropdownToggle caret className="nav-link btn commonBtn">
                                        Courses
                                    </DropdownToggle>
                                    <DropdownMenu>
                                    { courseName && courseName.length > 0 ? courseName.map((d)=>(
                                        d.coursedetails ?
                                        <DropdownItem  className="course" onClick={()=>navigate(`/coursedetails/${d._id}`)} >{d.coursetitle}</DropdownItem> : null ))  : <DropdownItem>No Courses !!</DropdownItem> }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </NavItem>
                            <NavItem className="pb-0">
                              {/* <NavLink onClick={()=>{navigate('/')}} className="anchor cursor-pointer">Corporate Training</NavLink> */}
                            </NavItem>
                            <NavItem className="pb-0">
                              <NavLink onClick={()=>navigate('/aboutus')} className="anchor cursor-pointer">About Us</NavLink>
                            </NavItem>
                            <NavItem className="pb-0">
                              {/* <NavLink onClick={()=>{navigate('/')}} className="anchor cursor-pointer">Testimonial</NavLink> */}
                            </NavItem>
                            <NavItem className="pb-0">
                              <NavLink onClick={()=>navigate('/contact')} className="anchor cursor-pointer">Contact Us</NavLink>
                            </NavItem>
                            <NavItem className="pb-0">
                              <NavLink onClick={()=>{navigate('/contact')}} className="anchor btn commonBtn">Enquire Now</NavLink>
                            </NavItem>
                          </Nav>
                        </Collapse>
                        {/* <div className="collapse navbar-collapse  justify-content-end myNav" id="myNavbar">
                            <ul className="navbar-nav justify-content-center align-items-center">
                                <li className="nav-item  pb-0">
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret className="nav-link btn commonBtn">
                                            Courses
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Course 1</DropdownItem>
                                            <DropdownItem>Course 2</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </li>
                                <li className="nav-item pb-0">
                                    <a className="anchor nav-link " href="">Corporate Training</a>
                                </li>
                                <li className="nav-item pb-0">
                                    <a className="anchor nav-link " href="">About Us</a>
                                </li>
                                <li className="nav-item pb-0">
                                    <a className="anchor nav-link " href="">Testimonials</a>
                                </li>
                                <li className="nav-item pb-0">
                                    <a className="anchor nav-link " href="">Contact Us</a>
                                </li>
                                <li className="nav-item pb-0">
                                    <a className="nav-link btn commonBtn" href="">Enquire Now</a>
                                </li>

                            </ul>
                        </div> */}
                    </nav>
                </div>
            </div>
        </header>
      </>
   );
}
export default Header;